.defbuttonContainer {
    @apply bg-white leading-none shadow-xl transition-all;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 5px;
    font-weight: 600;

    &:hover {
        @apply bg-cherry-200 text-white;
    }
}