.container {
    @apply w-full grid grid-cols-2 grid-rows-1 bg-white;

    .side_1 {
        @apply flex justify-center items-center relative;
        width: 50vw;
        height: 90vh;
        .image {
            @apply object-contain aspect-square;
            width: 600px;
            height: 600px;
        }

        .paginatorContainer {
            @apply absolute bottom-0 left-0 p-3 flex flex-wrap gap-2;

            .item {
                @apply object-contain bg-cover transition-all border-2 rounded-md shadow-sm cursor-pointer;
                width: 80px;
                height: 80px;
                &:hover {
                    @apply border-cherry-200/80;
                }
                &:to {
                    @apply border-cherry-200/80;
                }
            }

            .selected {
                @apply border-cherry-200;
            }
        }
    }
    .side_2 {
        @apply bg-bluesky-100 flex items-center relative;

        .extendContainer {
            @apply absolute top-0 left-0 w-full h-full;

            .extend {
                @apply w-full h-full object-cover bg-cover opacity-40;
            }
        }

        .contentContainer {
            @apply w-full h-full flex flex-col justify-center z-10;
            padding-left: 20%;
            padding-right: 20%;
            gap: 20px;
            .title {
                @apply text-2xl;
                font-weight: 700;
            }

            .desc {
                @apply text-xl;
                font-weight: 500;
            }

            .article {
                font-weight: 500;
            }
        }
    }
}

// Adaptive Size [MM]
@media (min-width: 310px) and (max-width: 640px) {
    
    .container {
        @apply mm:flex mm:flex-col;
        
        .side_1 {
            @apply mm:w-full;
    
            .image {
                height: 350px;
                width: 350px;
            }
    
            .paginatorContainer {
                @apply mm:p-2 mm:w-full;
            }
        }
        .side_2 {
            .contentContainer {
                padding-top: 20%;
                padding-bottom: 20%;
            }
        }
    }
}

// Adaptive Size [SM]
@media (min-width: 640px) and (max-width: 767px) {
    
    .container {
        @apply sm:flex sm:flex-col;
        
        .side_1 {
            @apply sm:w-full;
    
            .image {
                height: 450px;
                width: 450px;
            }
        }
        .side_2 {
            .contentContainer {
                padding-left: 10%;
                padding-right: 10%;
                padding-top: 10%;
                padding-bottom: 10%;
            }
        }
    }
}

// Adaptive Size [MD]
@media (min-width: 768px) and (max-width: 1023px) {
    
    .container {
        @apply md:flex md:flex-col;
        
        .side_1 {
            @apply md:w-full;
    
            .image {
                height: 500px;
                width: 500px;
            }
        }
        .side_2 {
            .contentContainer {
                padding-left: 7%;
                padding-right: 7%;
                padding-top: 7%;
                padding-bottom: 7%;
            }
        }
    }
}

// Adaptive Size [LG]
@media (min-width: 1024px) and (max-width: 1279px) {
    
    .container {
        @apply lg:flex lg:flex-col;
        
        .side_1 {
            @apply lg:w-full;
    
            .image {
                height: 500px;
                width: 500px;
            }
        }
        .side_2 {
            .contentContainer {
                padding-left: 7%;
                padding-right: 7%;
                padding-top: 7%;
                padding-bottom: 7%;
            }
        }
    }
}

// Adaptive Size [XL]
@media (min-width: 1280px) and (max-width: 1535px) {
    
    .container {  
        .side_1 {
            @apply xl:w-full;
    
            .image {
                height: 500px;
                width: 500px;
            }
        }
    }
}


// Adaptive Size [FULL]