.catalogContainer {
	@apply bg-bluesky-100 grid grid-cols-7 grid-rows-1;

	.filterGridContainer {
		@apply w-full col-span-2 relative;
		padding-left: 10%;
		padding-right: 10%;
		padding-top: 40px;
		padding-bottom: 40px;
		.filterGridWrapper {
			@apply bg-white flex flex-col sticky left-0 shadow-sm rounded-[15px] rounded-br-none rounded-tr-none transition-all select-none;

			gap: 15px;
			padding-left: 20px;
			padding-right: 20px;
			padding-top: 10px;
			padding-bottom: 10px;
			top: 12%;
			.headerFilter {
				@apply flex justify-between items-center w-full;

				.title {
					@apply text-lg;
					font-weight: 700;
				}

				.turnButton {
					@apply text-xl cursor-pointer;
					font-weight: 700;

					&:hover {
						@apply text-red-500;
					}
				}
			}

			.mainFilter {
				@apply flex-col select-none;
				gap: 10px;
				padding-left: 5%;
				padding-right: 5%;

				.item {
					@apply cursor-pointer transition-all;
					font-weight: 500;

					&:hover {
						@apply text-cherry-200;
					}

					&.selected {
						@apply text-cherry-200;
					}
				}
			}

			.opened {
				@apply flex;
			}
			.closed {
				@apply hidden;
			}
		}
	}

	.productContainer {
		@apply col-span-5 w-full grid grid-cols-4 items-start h-min;
		column-gap: 30px;
		row-gap: 30px;
		padding-top: 40px;
		padding-bottom: 40px;
		padding-left: 20px;
		padding-right: 20px;
		.paginatorContainer {
			@apply col-span-full;
		}
	}
	.productErrorContainer {
		@apply col-span-5 w-full grid grid-cols-4;
		column-gap: 30px;
		row-gap: 30px;
		padding-top: 40px;
		padding-bottom: 40px;
		padding-left: 20px;
		padding-right: 20px;
		.errorContainer {
			@apply col-span-full bg-cherry-200/80 w-full h-min flex justify-center items-center;
			border-radius: 15px;
			padding-top: 20px;
			padding-bottom: 20px;
			.errorMessage {
				@apply text-white text-center;
				font-weight: 600;
			}
		}
	}
}

// Adaptive Size [MM]
@media (min-width: 310px) and (max-width: 640px) {
	.catalogContainer {
		@apply mm:grid-cols-1;

		.filterGridContainer {
			padding-top: 2%;
			padding-bottom: 2%;
			padding-left: 2%;
			padding-right: 2%;
		}

		.productContainer {
			@apply mm:col-span-full mm:grid-cols-1 mm:px-0 mm:py-0;
			row-gap: 1px;

			.paginatorContainer {
				padding-left: 2%;
				padding-right: 2%;
				padding-top: 10px;
				padding-bottom: 10px;
			}
		}

		.productErrorContainer {
			padding-top: 2%;
			padding-bottom: 2%;
			padding-left: 2%;
			padding-right: 2%;
		}
	}
}

// Adaptive Size [SM]
@media (min-width: 640px) and (max-width: 767px) {
	.catalogContainer {
		@apply sm:grid-cols-1;

		.filterGridContainer {
			padding-top: 2%;
			padding-bottom: 2%;
			padding-left: 1%;
			padding-right: 1%;
		}

		.productContainer {
			@apply sm:col-span-full sm:grid-cols-2 sm:px-0 sm:py-0;
			row-gap: 10px;
			column-gap: 10px;

			.paginatorContainer {
				padding-left: 2%;
				padding-right: 2%;
				padding-top: 10px;
				padding-bottom: 10px;
			}
		}

		.productErrorContainer {
			padding-top: 2%;
			padding-bottom: 2%;
			padding-left: 2%;
			padding-right: 2%;
		}
	}
}

// Adaptive Size [MD]
@media (min-width: 768px) and (max-width: 1023px) {
	.catalogContainer {
		@apply md:grid-cols-1;

		.filterGridContainer {
			padding-top: 2%;
			padding-bottom: 2%;
			padding-left: 1%;
			padding-right: 1%;
		}

		.productContainer {
			@apply md:col-span-full md:grid-cols-3 md:px-0 md:py-0;
			row-gap: 10px;
			column-gap: 10px;

			.paginatorContainer {
				padding-top: 10px;
				padding-bottom: 10px;
				padding-left: 2%;
				padding-right: 2%;
			}
		}

		.productErrorContainer {
			padding-top: 2%;
			padding-bottom: 2%;
			padding-left: 2%;
			padding-right: 2%;
		}
	}
}

// Adaptive Size [LG]
@media (min-width: 1024px) and (max-width: 1279px) {
	.catalogContainer {
		@apply lg:grid-cols-1;

		.filterGridContainer {
			padding-top: 2%;
			padding-bottom: 2%;
			padding-left: 1%;
			padding-right: 1%;
		}

		.productContainer {
			@apply lg:col-span-full lg:grid-cols-3 lg:px-0 lg:py-0;
			row-gap: 10px;
			column-gap: 10px;
			.paginatorContainer {
				padding-left: 2%;
				padding-right: 2%;
				padding-top: 10px;
				padding-bottom: 10px;
			}
		}

		.productErrorContainer {
			padding-top: 2%;
			padding-bottom: 2%;
			padding-left: 2%;
			padding-right: 2%;
		}
	}
}

// Adaptive Size [XL]
@media (min-width: 1280px) and (max-width: 1535px) {
	.catalogContainer {
		.productContainer {
			@apply xl:grid-cols-3;

			.paginatorContainer {
				padding-top: 10px;
				padding-bottom: 10px;
			}
		}

		.productErrorContainer {
			padding-top: 2%;
			padding-bottom: 2%;
			padding-left: 2%;
			padding-right: 2%;
		}
	}
}

// Adaptive Size [FULL]
