.aboutusContainer {
	@apply bg-light;

	.imageContainer {
		@apply flex justify-center;
		height: 90vh;

		.image {
			@apply w-full h-full object-cover;
		}
	}

	// .divider {
	//     @apply bg-cherry-200 w-full py-[30px] relative;

	//     .sub_divider {
	//         @apply absolute bottom-0 left-0 w-full bg-black/20 py-[5px];
	//     }
	// }

	.blockContainer {
		@apply bg-light flex flex-col relative overflow-hidden;

		.blockGreen {
			@apply bg-bluesky-100;
		}

		.blockBlue {
			@apply bg-bluesky-100;
		}

		.blockItem {
			@apply flex justify-center relative;
			padding-top: 8%;
			padding-bottom: 6%;

			.imagegroup1_Container {
				@apply absolute flex justify-between w-full h-full top-0 left-0 overflow-hidden;
				padding-left: 20%;
				padding-right: 20%;

				.imageScaling {
					--tw-scale-x: 0.8;
					--tw-scale-y: 0.8;
					transform: translate(var(--tw-translate-x), var(--tw-translate-y))
						rotate(var(--tw-rotate)) skewX(var(--tw-skew-x))
						skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x))
						scaleY(var(--tw-scale-y));
				}
			}

			.imagegroup2_Container {
				@apply absolute flex justify-center w-full h-full top-0 left-0 overflow-hidden;
				gap: 3%;

				.image2Scaling1 {
					@apply mt-auto opacity-40;
					--tw-scale-x: 0.9;
					--tw-scale-y: 0.9;
					transform: translate(var(--tw-translate-x), var(--tw-translate-y))
						rotate(var(--tw-rotate)) skewX(var(--tw-skew-x))
						skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x))
						scaleY(var(--tw-scale-y));
				}

				.image2Scaling2 {
					@apply mb-auto opacity-80;
					--tw-scale-x: 0.8;
					--tw-scale-y: 0.8;
					transform: translate(var(--tw-translate-x), var(--tw-translate-y))
						rotate(var(--tw-rotate)) skewX(var(--tw-skew-x))
						skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x))
						scaleY(var(--tw-scale-y));
				}
			}

			.firstBlockContainer {
				@apply flex flex-col items-center z-10;
				gap: 30px;
				width: 40%;
				.title {
					@apply text-3xl text-center;
					font-weight: 600;
				}

				.desc {
					@apply text-lg;
					font-weight: 500;
				}
			}

			.lastBlockImage {
				@apply absolute flex justify-center w-full h-full top-0 left-0 overflow-hidden;

				.itemScaling {
					@apply mb-auto opacity-80 w-full;
					--tw-scale-x: 0.8;
					--tw-scale-y: 0.8;
					transform: translate(var(--tw-translate-x), var(--tw-translate-y))
						rotate(var(--tw-rotate)) skewX(var(--tw-skew-x))
						skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x))
						scaleY(var(--tw-scale-y));
				}
			}

			.lbContainer {
				@apply flex flex-col z-10;
				gap: 30px;
				padding-left: 90px;
				padding-right: 90px;
			}
			.title {
				@apply text-2xl text-center;
				font-weight: 600;
			}

			.gridContainer {
				@apply w-full grid grid-cols-11 grid-rows-3;
				gap: 20px;

				.firstItem {
					@apply bg-banana-100 flex items-center justify-center col-span-5;
					border-radius: 15px;
					padding-top: 15px;
					padding-bottom: 15px;
					padding-left: 16px;
					padding-right: 16px;
					.content {
						@apply text-center;
						font-weight: 500;
						font-size: 17px;
					}
				}

				.secondItem {
					@apply bg-lightgreen-100 flex items-center justify-center row-start-2 col-start-4 col-span-5;
					border-radius: 15px;
					padding-top: 15px;
					padding-bottom: 15px;
					padding-left: 16px;
					padding-right: 16px;
					.content {
						@apply text-center;
						font-weight: 500;
						font-size: 17px;
					}
				}

				.thirdItem {
					@apply bg-cherry-100 flex items-center justify-center row-start-3 col-start-7 col-span-6;
					border-radius: 15px;
					padding-top: 15px;
					padding-bottom: 15px;
					padding-left: 16px;
					padding-right: 16px;
					.content {
						@apply text-center;
						font-weight: 500;
						font-size: 17px;
					}
				}
			}
		}
	}
}

// Adaptive Size [MM]
@media (min-width: 310px) and (max-width: 640px) {
	.aboutusContainer {
		.blockContainer {
			.blockItem {
				.imagegroup1_Container {
					@apply mm:hidden;
				}

				.lastBlockImage {
					@apply mm:hidden;
				}

				.firstBlockContainer {
					width: 90%;
				}

				.lbContainer {
					padding-left: 10px;
					padding-right: 10px;

					.gridContainer {
						.firstItem {
							@apply mm:col-span-full;
						}

						.secondItem {
							@apply mm:col-span-full;
						}

						.thirdItem {
							@apply mm:col-span-full;
						}
					}
				}
			}
		}
	}
}

//Adaptive Size [SM]
@media (min-width: 640px) and (max-width: 767px) {
	.aboutusContainer {
		.blockContainer {
			.blockItem {
				.imagegroup1_Container {
					@apply sm:hidden;
				}

				.lastBlockImage {
					@apply sm:hidden;
				}

				.firstBlockContainer {
					width: 90%;
				}

				.lbContainer {
					padding-left: 10px;
					padding-right: 10px;

					.gridContainer {
						.firstItem {
							@apply sm:col-span-full;
						}

						.secondItem {
							@apply sm:col-span-full;
						}

						.thirdItem {
							@apply sm:col-span-full;
						}
					}
				}
			}
		}
	}
}

// Adaptive Size [MD]
@media (min-width: 768px) and (max-width: 1023px) {
	.aboutusContainer {
		.blockContainer {
			.blockItem {
				.imagegroup1_Container {
					@apply md:hidden;
				}

				.lastBlockImage {
					@apply md:hidden;
				}

				.firstBlockContainer {
					width: 90%;
				}

				.lbContainer {
					padding-left: 10px;
					padding-right: 10px;

					.gridContainer {
						.firstItem {
							@apply md:col-span-full;
						}

						.secondItem {
							@apply md:col-span-full;
						}

						.thirdItem {
							@apply md:col-span-full;
						}
					}
				}
			}
		}
	}
}

// Adaptive Size [LG]
@media (min-width: 1024px) and (max-width: 1279px) {
	.aboutusContainer {
		.blockContainer {
			.blockItem {
				.imagegroup1_Container {
					@apply lg:hidden;
				}

				.lastBlockImage {
					@apply lg:hidden;
				}

				.firstBlockContainer {
					width: 90%;
				}

				.lbContainer {
					padding-left: 10px;
					padding-right: 10px;
				}
			}
		}
	}
}

// Adaptive Size [XL]

.aboutusContainer {
	.blockContainer {
		.blockItem {
			.firstBlockContainer {
				width: 70%;
			}
		}
	}
}

// Adaptive Size [FULL]
