// Containers 

.paginator__classname {
   @apply flex flex-wrap gap-2 items-center justify-center select-none transition-all overflow-hidden w-full;
}

.paginator__container {
    
}

// Active - Disable links

.paginator__activeLink {
    @apply bg-cherry-200 text-white border-blue-500;
}

.paginator__active {
}

.paginator__disabled {
    @apply bg-white;
}


// Main Nodes

.paginator__next {
    @apply rounded-sm ml-auto hover:bg-cherry-200 hover:text-white;
}

.paginator__previous {
    @apply rounded-sm mr-auto hover:bg-cherry-200 hover:text-white;
}

.paginator__page {
    @apply font-bold flex items-center justify-center text-center rounded-sm hover:bg-white;
}

.paginator__break {
    
}

.paginator__breakLink {

}

.paginator__disabledLink {
   
}

.paginator__previousLink {
    @apply font-medium flex items-center justify-center text-center rounded-sm;
    width: 40px;
    height: 40px;
}

.paginator__nextLink {
    @apply font-medium flex items-center justify-center text-center rounded-sm;
    width: 40px;
    height: 40px;
}

.paginator__pageLink {
    @apply flex items-center justify-center text-center rounded-sm;
    width: 40px;
    height: 40px;
}

