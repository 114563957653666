.recblock {
    @apply bg-light px-2 w-full h-full flex flex-col items-center justify-center;
    padding-top: 100px;
    padding-bottom: 100px;
    gap: 60px;

    .titleblock {
        font-size: 30px;
        line-height: 40px;
        font-weight: 700;
    }

    .cardblock {
        @apply grid grid-cols-3;
        gap: 100px;
    }

    // Adaptive Size [MM]
    @media (min-width: 310px) and (max-width: 640px) {    
        @apply mm:text-center mm:w-full;
        .cardblock {
            @apply mm:flex mm:flex-col;
            gap: 30px;
        }
    }

    // Adaptive Size [SM]
    @media (min-width: 640px) and (max-width: 767px) {       
        @apply sm:text-center sm:w-full;
        .cardblock {
            @apply sm:grid-cols-2 sm:justify-center;
            gap: 25px;
        }
    }

    // Adaptive Size [MD]
    @media (min-width: 768px) and (max-width: 1023px) {     
        @apply md:text-center md:w-full;
        .cardblock {
            @apply md:grid-cols-3 md:justify-center;
            gap: 25px;
        }
    }

    // Adaptive Size [LG]
    @media (min-width: 1024px) and (max-width: 1279px) {
        @apply lg:text-center lg:w-full;
        .cardblock {
            @apply lg:flex lg:justify-center;
            gap: 60px;
        }
    }
}