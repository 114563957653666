.reccard {
    @apply flex flex-col items-center justify-center text-center;
    border-radius: 15px;
    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: 3%;
    padding-right: 3%;
    gap: 50px;
    max-width: 320px;

    .title {
        @apply text-center;
        font-weight: 600;
        line-height: 35px;
        font-size: 30px;
    }

    .image {
        width: 130px;
        height: 130px;
    }
}