
.productCardContainer {
    @apply bg-white overflow-hidden flex flex-col transition-all
    items-center cursor-pointer;
    border-radius: 15px;
    padding-top: 30px;
    padding-bottom: 30px;
    gap: 15px;

    @apply mm:rounded-none;

    &:hover {
        @apply shadow-lg;
    }

    .img {
        @apply bg-cover object-contain aspect-square;
        width: 250px;
        height: 250px;
    }

    .title {
        @apply text-center text-lg;
        font-weight: 700;
        padding-left: 10px;
        padding-right: 10px;
    }
}