.container {
    @apply flex flex-col w-full items-center;
    
    .infoContainer {
        @apply flex items-center justify-center bg-lightgreen-100/40 w-full;
        padding-top: 15px;
        padding-bottom: 15px;
        .message {
            @apply font-[400] text-xl text-center;
            font-weight: 400;

            .link {
                @apply text-blue-500 cursor-pointer;
            }
        }
    }

    .errorContainer {
        @apply  bg-cherry-200 flex justify-center items-end;
        width: 98%;
        border-radius: 15px;
        padding-top: 20px;
        padding-bottom: 20px;
        margin-top: 10px;
        margin-bottom: 10px;
        .message {
            @apply text-center text-white text-center;
            font-weight: 500;
        }
    }

    .table {
        @apply w-full divide-x divide-y border-y;

        .headerContainer {
            @apply divide-y;

            .block {
                @apply divide-x divide-y;

                .title {
                    @apply text-lg;
                    padding-top: 15px;
                    padding-bottom: 15px;
                    font-weight: 600;
                    width: 20%;
                }
            }
        }

        .mainContainer {
            @apply divide-y;

            .block {
                @apply divide-x divide-y;

                .item {
                    font-weight: 500;
                    padding-left: 20px;
                    padding-right: 20px;
                    .categoryitem {
                        @apply flex items-center;
                        gap: 5px;
                        .imageitem {
                            @apply object-contain;
                            height: 64px;
                            width: 64px;
                        }
                    }

                    .removebtn {
                        @apply w-full h-full flex justify-center text-cherry-200;
                    }
                    .editbtn {
                        @apply w-full h-full flex justify-center text-green-500;
                    }
                }
            }
        }
    }

    .paginatorContainer {
        @apply w-full;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 10px;
        padding-right: 10px;
        &.visible {
            @apply flex;
        }

        &.hide {
            @apply hidden;
        }
    }
}

// Adaptive Size [MM]
// Adaptive Size [SM]
// Adaptive Size [MD]
// Adaptive Size [LG]
// Adaptive Size [XL]
// Adaptive Size [FULL]