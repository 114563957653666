.simplemainbanner {
    @apply w-full bg-banana-100 grid grid-cols-9 grid-rows-1;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 50px;
    padding-right: 50px;
    gap: 100px;

    .firstside {
        @apply col-span-6 flex flex-col items-center justify-center relative;
        padding-left: 20%;  
        gap: 40px;

        .title {
            font-size: 32px;
            line-height: 40px;
            font-weight: 700;
        }
        .desc {
            font-size: 20px;
            line-height: 25px;
            font-weight: 500;
            width: 70%;
        }

        .groupimagecontainer {
            @apply absolute top-0 left-0 w-full h-full flex justify-between items-center;
            padding-left: 10%;
            padding-right: 10%;
            .groupimage1 {
                @apply top-0 left-0;
            }
            .groupimage2 {
                --tw-translate-x: -20%;
                transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
            }
        }
    }


    .secondside {
        @apply col-span-3 overflow-hidden flex justify-center;
        .banner_3_img {
            @apply w-full bg-cover object-contain;
            height: 390px;
            border-radius: 15px;
        }
    }

    // Adaptive Size [MM]

    @media (min-width: 310px) and (max-width: 640px) {       
        @apply mm:grid-cols-1 mm:grid-rows-2 mm:gap-0 mm:w-full;
        padding-left: 10px;
        padding-right: 10px;
    
        .firstside {
            @apply mm:p-0 mm:text-center;
    
            .desc {
                @apply mm:w-full
            }
    
            .groupimagecontainer {
                @apply mm:hidden
            }
        }
    
        .secondside {
            @apply mm:col-span-full mm:px-0;
        }
    }


    // Adaptive Size [SM]

    @media (min-width: 640px) and (max-width: 767px) {       
        @apply sm:grid-cols-1 sm:grid-rows-2 sm:gap-0 sm:w-full;
        padding-left: 10px;
        padding-right: 10px;
    
        .firstside {
            @apply sm:p-0 sm:text-center;
    
            .desc {
                @apply sm:w-full
            }
    
            .groupimagecontainer {
                @apply sm:hidden
            }
        }
    
        .secondside {
            @apply sm:col-span-full sm:px-0;
        }
    }


    // Adaptive Size [MD]

    @media (min-width: 768px) and (max-width: 1023px) {     
        @apply md:gap-0 md:w-full;
        padding-left: 10px;
        padding-right: 10px;
    
        .firstside {
            @apply md:col-span-5;
            padding-left: 10px;
            padding-right: 10px;
    
            .groupimagecontainer {
                @apply md:hidden;
            }
        }
    
        .secondside {
            @apply md:col-span-4;
        }
    }

    // Adaptive Size [LG]

    @media (min-width: 1024px) and (max-width: 1279px) {       
        @apply lg:gap-0 lg:w-full;
        padding-left: 10px;
        padding-right: 10px;
    
        .firstside {
            @apply lg:col-span-5;
            padding-left: 20px;
            padding-right: 20px;
    
            .groupimagecontainer {
                @apply lg:hidden;
            }
        }
    
        .secondside {
            @apply lg:col-span-4;
        }
    }


     // Adaptive Size [XL]
     @media (min-width: 1280px) and (max-width: 1535px) {     
         @apply xl:gap-0 xl:w-full;
         padding-left: 10px;
         padding-right: 10px;

         .firstside {
    
            .groupimagecontainer {
                @apply xl:hidden;
            }
        }
    }
}