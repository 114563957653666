.contactContainer {
    @apply grid grid-cols-8 grid-rows-1 w-full  bg-bluesky-100 relative;
    height: 90vh;
    padding-top: 15%;
    .extend {
        @apply absolute top-0 left-0 w-full h-full flex justify-center;

        .image {
            @apply opacity-70 w-full h-full;
            --tw-scale-x: 0.9;
            --tw-scale-y: 0.9;
            transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
        }
    }

    .side_1 {
        @apply col-start-2 col-span-3 flex flex-col z-10;
        gap: 40px;
        .title {
            @apply text-3xl;
            font-weight: 700;
        }
        
        .descContainer {
            @apply flex flex-col;
            gap: 20px;
            .subtitle {
                @apply text-xl;
                font-weight: 700;
            }

            .desc {
                @apply text-lg;
            }
        }
    }

    .side_2 {
        @apply col-span-4 flex flex-col z-10;
        gap: 40px;
        padding-right: 30%;
        .title {
            @apply text-3xl relative;
            font-weight: 700;
            .pen {
                @apply absolute;
                top: -140%;
                left: -8%;
                --tw-scale-x: 0.8;
                --tw-scale-y: 0.8;
                transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
            }
        }

        .formContainer {
            @apply flex flex-col;
            gap: 15px;
            .buttonContainer {
                @apply flex w-full justify-end;

                .buttonWrapper {
                    --tw-scale-x: 0.8;
                    --tw-scale-y: 0.8;
                    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
                    --tw-translate-x: 10%;
                    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
                }
            }
        }
    }
}

// Adaptive Size [MM]
@media (min-width: 310px) and (max-width: 640px) {
    
    .contactContainer {
        @apply mm:flex mm:flex-col-reverse;
        padding-top: 20%;
        padding-bottom: 20%;
        gap: 40px;
        .extend {
            @apply mm:hidden;
        }
    
        .side_1 {
            @apply mm:col-span-full;
            padding-left: 2%;
            padding-right: 2%;
        }
    
        .side_2 {
            @apply mm:col-span-full mm:overflow-hidden;
            padding-left: 2%;
            padding-right: 2%;
            .title {
                .pen {
                    @apply mm:hidden;
                }
            }
        }
       
    }
}
// Adaptive Size [SM]
@media (min-width: 640px) and (max-width: 767px) {
    
    .contactContainer {
        @apply sm:flex sm:flex-col-reverse sm:h-full;
        padding-top: 10%;
        padding-bottom: 10%;
        gap: 40px;
        .extend {
            @apply sm:hidden;
        }
    
        .side_1 {
            @apply sm:col-span-full;
            padding-left: 2%;
            padding-right: 2%;
        }
    
        .side_2 {
            @apply sm:col-span-full sm:overflow-hidden;
            padding-left: 2%;
            padding-right: 2%;
            .title {
                .pen {
                    @apply sm:hidden;
                }
            }
        }
       
    }
}
// Adaptive Size [MD]
@media (min-width: 768px) and (max-width: 1023px) {
    
    .contactContainer {
        @apply md:flex md:flex-col-reverse md:h-full;
        padding-top: 5%;
        padding-bottom: 5%;
        gap: 40px;
        .extend {
            @apply md:hidden;
        }
    
        .side_1 {
            @apply md:col-span-full;
            padding-left: 2%;
            padding-right: 2%;
        }
    
        .side_2 {
            @apply md:col-span-full md:overflow-hidden;
            padding-left: 2%;
            padding-right: 2%;
            .title {
                .pen {
                    @apply md:hidden;
                }
            }
        }
       
    }
}
// Adaptive Size [LG]
@media (min-width: 1024px) and (max-width: 1279px) {
    
    .contactContainer {
    
        .extend {
            @apply lg:hidden;
        }
    
        .side_2 {
            padding-right: 5%;
            .title {
                .pen {
                    @apply lg:hidden;
                }
            }
        }
       
    }
}

// Adaptive Size [XL]
@media (min-width: 1280px) and (max-width: 1535px) {
    
    .contactContainer {
    
        .extend {
            @apply xl:hidden;
        }
    
        .side_2 {
            padding-right: 5%;
            .title {
                .pen {
                    @apply xl:hidden;
                }
            }
        }
       
    }
}

// Adaptive Size [FULL]