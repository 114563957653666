.adminLayoutContainer {
    @apply grid grid-cols-12 grid-rows-1 bg-white min-h-screen relative;

    .headerWrapper {
        @apply col-span-2;

        .headerContainer {
            @apply flex flex-col border-r sticky top-0 left-0 h-screen;
            gap: 15px;

            .infoBlock {
                @apply flex flex-col items-center justify-center;
                gap: 10px;

                .avatar {
                    @apply rounded-full shadow-xl p-2;
                    width: 152px;
                    height: 152px;
                }

                .username {
                    @apply text-xl text-center;
                    font-weight: 500;
                }

                .email {
                    @apply text-center;
                    font-weight: 500;
                }
            }

            .listContainer {
                @apply w-full h-full flex flex-col select-none;
                padding-bottom: 10%;

                .item {
                    @apply cursor-pointer transition-all;
                    padding-left: 20px;
                    padding-right: 20px;
                    padding-top: 15px;
                    padding-bottom: 15px;
                    font-weight: 500;
                    
                    &:hover {
                        @apply bg-light;
                    }
                }


                &.selected {
                    @apply bg-cherry-200 text-white;
                }
            }

            .external {
                @apply mt-auto divide-y;

                .exititem {
                    @apply text-dark mt-auto cursor-pointer transition-all;
                    padding-left: 20px;
                    padding-right: 20px;
                    padding-top: 15px;
                    padding-bottom: 15px;
                    font-weight: 500;
                    &:hover {
                        @apply bg-light;
                    }
                }
            }

        }
    }
}

.contentContainer {
    @apply col-span-10;
}

// Adaptive Size [MM]
@media (min-width: 310px) and (max-width: 640px) {
    .adminLayoutContainer {
        @apply mm:grid-cols-1;
    
        .headerWrapper {
            @apply mm:col-span-full;
    
            .headerContainer {
                @apply mm:h-auto;
    
                .listContainer {
                    .external {
                        margin-top: 20px;
                    }
                }
            }
        }
    
        .contentContainer {
            @apply mm:col-span-full;
        }
    }
}

// Adaptive Size [SM]
@media (min-width: 640px) and (max-width: 767px) {
    .adminLayoutContainer {
        @apply sm:grid-cols-1;
    
        .headerWrapper {
    
            .headerContainer {
                @apply sm:h-auto;

                .listContainer {
                    .external {
                        margin-top: 20px;
                    }
                }
            }
        }
    
        .contentContainer {
            @apply sm:col-span-full;
        }
    }
}

// Adaptive Size [MD]
@media (min-width: 768px) and (max-width: 1023px) {
    .adminLayoutContainer {
        @apply md:grid-cols-1;
    
        .headerWrapper {
            .headerContainer {
                @apply md:h-auto;
    
                .listContainer {
                    .external {
                        margin-top: 20px;
                    }
                }
            }
        }
    
        .contentContainer {
            @apply md:col-span-full;
        }
    }
}

// Adaptive Size [LG]
.adminLayoutContainer {
    .headerWrapper {
        @apply lg:col-span-3;
    }

    .contentContainer {
        @apply lg:col-span-9
    }
}

// Adaptive Size [XL]
.adminLayoutContainer {
    .headerWrapper {
        @apply xl:col-span-3;
    }

    .contentContainer {
        @apply xl:col-span-9
    }
}

// Adaptive Size [FULL]